import React from 'react'
import { Link } from 'react-router-dom'
import { getTrialDaysLeft } from '../../utils/utils'
import useCustomerStore from '../../stores/customerStore'

export default function TrialInformation() {
  const trialData = useCustomerStore((state) => state.trialData)

  const getTrialScenariosLeft = () => {
    return 5 - trialData.numberOfScenariosUsed
  }

  return (
    <>
      {trialData && (
        <Link
          to={`/customer/${trialData?.customerIdentifier}?activeTab=billing`}
          className="navbar-item"
        >
          <p className="subtitle mb-0">
            <span>{Math.max(0, getTrialDaysLeft(trialData))} days left in your trial, </span>
            {getTrialScenariosLeft() > 0 && (
              <span>{getTrialScenariosLeft()} free Knowledge Graph scenarios left to use</span>
            )}
            {!(getTrialScenariosLeft() > 0) && <span>no Knowledge Graph scenarios left.</span>}
          </p>
        </Link>
      )}
    </>
  )
}
